html{
  scroll-behavior: smooth;
}
  
/* Cookie consent stylings */
.buttonWrapper {
  width: fit-content;
  display: flex;
  gap: 1rem;
}

#rcc-confirm-button:hover, 
#rcc-decline-button:hover {
  transform: scale(1.02);
}

@media(max-width: 600px) {
  .buttonWrapper {
    justify-content: center;
    width: 100%;
    gap: 1rem;
  } 
}

  /* Custom stylings for the toast */
.Toastify__toast-body {
  color: #fff    ;
  font-size: 16px;
  padding-left: 0px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 30px !important;
  gap: 8%;
}
 
.Toastify__close-button {
  margin-top: 13px;
  margin-right: 10px;
  transform: scale(1.1);
} 

.Toastify__toast--success > div > .Toastify__toast-icon{
   content: url('../assets/check.svg');
   transform: scale(1.3);
}
.Toastify__toast--error > div > .Toastify__toast-icon{
   content: url('../assets/error.svg');
   transform: scale(1.3);
}
.Toastify__toast--warning > div > .Toastify__toast-icon{
   content: url('../assets/warning.svg');
   transform: scale(1.3);
}
.Toastify__toast--info > div > .Toastify__toast-icon{
   content: url('../assets/info.svg');
   transform: scale(1.3);
}

.Toastify__toast--success {
  border: 2px solid #008048 !important;
  border-radius: 12px !important;
  background: #202030 !important;
}

.Toastify__toast--error {
  border: 2px solid #E94926;
  border-radius: 12px !important;
  background: #202030 !important;
}

.Toastify__toast--warning {
  border: 2px solid #F1C40F  !important;
  border-radius: 12px !important;
  background: #202030 !important;
}

.Toastify__toast--info {
  border: 2px solid #4Bc2fA  !important;
  border-radius: 12px !important;
  background: #202030 !important;
}

body {
  box-sizing: border-box;
  margin: 0;
}