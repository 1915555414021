.rdrCalendarWrapper {
  background: #4f4e52 !important;
  font-size: 10px !important;
  border-radius: 12px !important;
}
.rdrDateDisplayWrapper {
  background-color: #4f4e52 !important;
  border-radius: 12px !important;
}
.rdrMonthAndYearPickers select {
  color: #616161 !important;
}
.rdrWeekDay{
  color: #eff2f7 !important;
}
.rdrMonthName{
  color: #eff2f7 !important;
}
.rdrDayNumber span{
  color: #eff2f7 !important;
}
.rdrDayPassive span{
  color: #849095 !important;
}
.rdrMonth{
  padding: 0,0,0,0 !important;
}
.rdrMonthPicker, .rdrYearPicker {
  background: #eff2f7 !important;
}
.rdrDayToday .rdrDayNumber span:after {
  background: #5bc2fa !important;
}
.rdrStartEdge{
  color: #5bc2fa !important;
}
.rdrInRange{
  color: #5bc2fa !important;
}
.rdrEndEdge{
  color: #5bc2fa !important;
}
.rdrDayHovered{
  color: #5bc2fa !important;
}
.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  color: #5bc2fa !important;
}