

.sound-wave {
  background-color: transparent;
  position: absolute;
}

.inplace-editor p {
  margin-bottom: 8px;
}

/* The first and last p-element under a .inplace-editor has no padding
 * to make it look like the text is aligned with the rest of the text
 * on the page. 
 */
.inplace-editor p:first-child,
.inplace-editor p:last-child {
  padding: 0;
  margin: 0;
}

.inplace-editor h1 {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.inplace-editor h2 {
  font-size: 1.7rem;
  font-weight: bold;
}

.inplace-editor h1 {
  font-size: 1.3rem;
  font-weight: bold;
}


/*
TODO: Change all containers to be relative to body and this
will fix the orientation issue.

@media only screen and (orientation: landscape) {
  body {
    height: 100vw;
    transform: rotate(90deg);
  }
} 
*/

